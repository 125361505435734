<template>
<section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <h2>{{msg}}</h2>
                <div>   
                </div>
            </div>

            <div id="incidencia" class="row bg-white has-shadow">
                <div class="col-md-9 offset-md-1">
                    <form class="form-horizontal mt-3 mb-3 ml-3">

                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Nombre de la Ciudad</label>
                            <div class="col-sm-3">
                                <input type="text" class="form-control form-control-success" v-model="registro.nombre">                            
                            </div>
                        </div>                        

                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Nombre del Estado</label>
                            <div class="col-sm-3">
                                <input type="text" class="form-control form-control-success" v-model="registro.estado">                            
                            </div>
                        </div> 

                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Zona</label>
                            <div class="col-sm-5">
                            <select name="account" class="form-control" v-model="registro.zona">
                                <option v-for="zona in zonas" :value="zona.id">{{ zona.nombre }}</option>                
                            </select>
                            </div>
                        </div>   

                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Región</label>
                            <div class="col-sm-5">
                            <select name="account" class="form-control" v-model="registro.region">
                                <option v-for="region in regiones" :value="region.id">{{ region.nombre }}</option>                
                            </select>
                            </div>
                        </div>                    
                        
                        <b-btn class="float-right mr-3 ml-3 mt-3" variant="primary" @click="grabar">Grabar</b-btn>
                        <b-btn class="float-right mr-3 ml-3 mt-3" variant="secundary" @click="regresar">Regresar</b-btn>

                    </form>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            msg: 'Editar Ciudad',
            zonas: this.$route.params.zonas,
            registro: this.$route.params.ciudad,
            regiones: [{'id':'XXXX','nombre': 'NO ASIGNADA'},{'id': 'TSUR', 'nombre': 'TICKETS SUR'}, {'id': 'TBAJ', 'nombre': 'TICKETS BAJIO'}, {'id': 'TNOR', 'nombre': 'TICKETS NORTE'}],
        }
    },
    methods: {
        grabar(){            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/ciudades", this.registro)
            .then((response) => {                
                this.$router.replace({ path: '/portal/ciudades'});                                                                 
            }, (error) => {
                //console.log("Error: " + JSON.stringify( error.data) );
            })
        },
        regresar(){
            this.$router.push({ path: '/portal/ciudades'});
            //this.$router.go(-2);
        },
    }

}
</script>

<style scoped>
    small.text-info{color: #7000ff}
    div#incidencia{margin-top: 10px; padding-bottom: 20px;}
    section.dashboard-header{padding: 10px 0;}  
    div.autocomplete{width: 400px}
</style>
